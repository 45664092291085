import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/home/HomePage.vue';

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/what-are-temp-coins',
    component: () => import('../views/home/WhatAreTempPage.vue'),
  },
  {
    path: '/getting-started',
    component: () => import('../views/home/GettingStartedPage.vue'),
  },
  {
    path: '/sign-up',
    component: () => import('../views/registration/RegistrationPage.vue'),
  },
  {
    path: '/sign-up/verify',
    component: () => import('../views/registration/RegistrationVrifyPage.vue'),
  },
  {
    path: '/sign-in',
    component: () => import('../views/registration/SigninPage.vue'),
  },
  {
    path: '/reset-password',
    component: () => import('../views/registration/ResetPasswordPage.vue'),
  },
  {
    path: '/reset-password/success',
    component: () => import('../views/registration/ResetPasswordConfirmPage.vue'),
  },
  {
    path: '/complete-profile-address',
    component: () => import('../views/complete_profiile/AddressPostcodePage.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
