
<template>
  <ion-page>
    <ion-content fullscreen class="ion-no-padding">
      <section class="container complete-profile-manual">
        <div class="welcome-heading">
          <h2>Welcome to</h2>
          <h3>Temp Rewards</h3>
          <h4>The only app where you can...</h4>
          <ul class="bullet-text-home">
            <li>
              <i
                ><img src="../../theme/images/bullet-icon.svg" alt="TempRewards"
              /></i>
              See the latest jobs in your area, first!
            </li>
            <li>
              <i
                ><img src="../../theme/images/bullet-icon.svg" alt="TempRewards"
              /></i>
              Earn TempCoins on top of your salary!
            </li>
            <li>
              <i
                ><img src="../../theme/images/bullet-icon.svg" alt="TempRewards"
              /></i>
              Be part of a Temp Community!
            </li>
          </ul>
        </div>
        <div class="complete-grid-outr">
          <div class="row">
            <div class="col-sm-8 complete-text-col">
              <div class="complete-text-outr">
                <p class="complete-txt">
                  Complete your registration and earn 250 TempCoins
                </p>
                <p class="temp-text">250 TempCoins = A Coffee on us!</p>
                <p class="offer-text">*offer expires 31st May 2022</p>
              </div>
            </div>
            <div class="col-sm-4 complete-img-col">
              <div class="complete-img-outr">
                <img
                  src="../../theme/images/cofee-pic.svg"
                  alt="TempRewards"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </ion-content>
    <ion-footer>
      <div class="did-txt-outr">
        <p>
          Did a friend tell you about Temp Rewards? Enter their referral code
          <span><a v-on:click="openContent()">here</a></span>
        </p>
        <div class="ref-form-outr">
          <form class="ref-form">
            <label>Referral Code</label>
            <input class="form-control" type="text" name="ref-code" />
            <button class="btn ref-btn" type="submit">
              Submit
              <i class="pull-right pad-0"
                ><img src="../../theme/images/submit-gray-arrow.svg"
              /></i>
            </button>
          </form>
        </div>
      </div>
      <div class="skip-sec-outr">
        <RouterLink to="/sign-up">Skip</RouterLink>
        <ul class="pagination-mnu">
          <RouterLink to="/home"><li class="active"></li></RouterLink>
          <RouterLink to="/what-are-temp-coins"><li></li></RouterLink>
          <RouterLink to="/getting-started"><li></li></RouterLink>
        </ul>
        <RouterLink to="/what-are-temp-coins">Next</RouterLink>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonFooter } from "@ionic/vue";

export default {
  components: {
    IonPage,
    IonContent,
    IonFooter,
  },
  methods: {
    openContent: function () {
      const refContent = document.querySelector(".ref-form-outr");
      refContent.style.display = "block";
    },
  },
};
</script>

<style>
.pagination-mnu li{
  margin: 0px 5px;
}
</style>